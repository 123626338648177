import React from "react";
import Header from "../components/header/Header";
import Slider from "../components/slider/Slider";
import Advantages from "../components/advantages/Advantages";
import Resume from "../components/resume/Resume";
import Contact from "../components/contact/Contact";
import ContactInfo from "../components/contact/ContactInfo";
import Map from "../components/contact/Map";
import Footer from "../components/footer/Footer";
import useDocumentTitle from "../components/useDocumentTitle";
import Biography from "../components/biography/Biography";
import ProductList from "../components/product/product";

const products = [
  {
    img: "product-1",
  },
  {
    img: "product-2",
  },
  {
    img: "product-3",
  },
  {
    img: "product-4",
  },
  {
    img: "product-5",
  },
  {
    img: "product-6",
  },
];
const Home = () => {
  useDocumentTitle("Ecosystem KIT");

  return (
    <div className="main-left theme-dark">
      <Header />

      <Slider />

      <Advantages />

      <Resume />

      <div className="section theme-light dark-bg">
        <div className="container">
          <div id="biography">
            <Biography />
          </div>
        </div>
      </div>

      <ProductList products={products} />

      <section id="contactus" className="section theme-light dark-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4 m-15px-tb">
              <ContactInfo />
            </div>

            <div className="col-lg-7 ml-auto m-15px-tb  ">
              <div className="contact-form">
                <h4>Узнать о внедрении системы</h4>
                <Contact />
              </div>
            </div>

            <div className="col-12">
              <Map />
            </div>
          </div>
        </div>
      </section>

      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  );
};

export default Home;
