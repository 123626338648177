const resumeContent = [
  {
    img: `manager`,
    companyName: "Снятие рутины с менеджеров",
    jobDescription: `Уведомление пациентов, сбор подтверждения, работа с отменами, рост возвращаемости сбор обратной связи - теперь задачи KIT MED`,
  },
  {
    img: `mis`,
    companyName: "Полная интеграция с МИС",
    jobDescription: `В которой сразу отображается подтверждение, отмена и новые визиты`,
  },
  {
    img: `coffee-and-phone`,
    companyName: "Удобный сервис в режиме 24/7 через мессенджер",
    jobDescription: `Люди проводят больше всего времени в мессенджере,там и работает наш сервис, он интуитивно понятен и доступен круглые сутки. Никакие доп. приложения просто не нужны`,
  },
  {
    img: `price`,
    companyName: "Прозрачная стоимость",
    jobDescription: `Оплата прозрачная, 1 лицензия вне зависимости от количества юр.лиц и филиалов`,
  },
  {
    img: `works-base-patients`,
    companyName:
      "Фокус на постоянной, автоматизированной работе с базой пациентов",
    jobDescription: `Автоматизация процессов работы клиники по заданным сценариям и сегментам пациентов`,
  },
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Возможности</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div className="resume-row" key={i}>
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <div className={"feature-box-02"}>
                        <img
                          className="icon"
                          src={`static/img/award/${val.img}.jpg`}
                          alt="award"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.companyName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
