import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>Заинтересовал проект?</h4>

        <p>
          Выбирайте удобный вид связи и сделайте первый шаг, остальное сделает
          KIT MED 😎
        </p>

        <ul>
          <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body">
              Ростов-на-Дону, Красноармейская, 170
            </span>
          </li>


          <li className="media">
            <i className="icon icon-envelope"></i>
            <a href="mailto:med@ecosystemkit.ru">
              <span className="media-body">med@ecosystemkit.ru</span>
            </a>
          </li>


          <li className="media">
            <i className="icon icon-phone"></i>
            <a href="tel:+79198914107">
              <span className="media-body">7 (919) 891-41-07</span>
            </a>
          </li>

        </ul>
      </div>

    </>
  );
};

export default ContactInfo;
