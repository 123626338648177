import React from "react";

const ServiceContent = [
  {
    icon: "icon-target",
    title: "Напоминания о текущих визитах",
    descriptions: `Текущие напоминания позволяют не забыть о близком по дате приеме, можно видеть напоминания как по своим приемам, так и по ребенку.`,
  },
  {
    icon: "icon-target",
    title: "Напоминания о плановых визитах",
    descriptions: `Например, прием у гинеколога рекомендован 1 раз в год: поэтому уведомление автоматически придет пациенту через год, конверсия возврата из первичных визитов в повторные растет.`,
  },
  {
    icon: "icon-target",
    title: "Возврат отмененных визитов",
    descriptions: `При отмене сразу предложим перезаписаться на новое время и уведомим администратора для возможного прозвона пациента.`,
  },
  {
    icon: "icon-target",
    title: "Работа с обратной связью и рост NPS",
    descriptions: `После визита запрашиваем отзывы (на ПроДокторов и др площадках), что увеличивает приток новых клиентов, а при негативном отзыве сразу уведомляем администратора для отработки возражений.`,
  },
  {
    icon: "icon-target",
    title: "Вариативный кабинет и меню бота для пациента",
    descriptions: `Пациент может смотреть график текущих записей, подтверждать и отменять их, видеть карточку по каждому врачу и примеры его работ, например, с фото по пластическим операциям, смотреть отзывы о врачах, схемы проезда, заказать справку для НДФЛ и скачать чек-лист после приема.`,
  },
  {
    icon: "icon-target",
    title: "Адаптация системы под ваши запросы: работаем через интеграцию с WhatsApp и Telegram",
    descriptions: `Разработаем нужный вашей клинике функционал и дополнительную интеграцию мессенджеров  помимо МИС с CRM и сторонними сервисами.`,
  },
];
export default function Tools() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
