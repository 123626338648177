import React from "react";

const Awards = ({ AwardContnet }) => {
  return (
    <>
      <div className="row">
        {AwardContnet.map((val, i) => (
          <div className="col-lg-4 m-15px-tb" key={i}>
            <div className="feature-box-02 d-flex align-items-center">
              <div className="icon">
                <img
                  src={`static/img/award/${val.img}.jpg`}
                  alt="Вы получаете рост"
                />
              </div>

              <div className="media-body">
                {val.awardName && <h6>{val.awardName}</h6>}
                {val.awardFor && <p>{val.awardFor}</p>}
              </div>
            </div>
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
