import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import {
  FaComment,
  FaLink,
  FaPhone,
  FaRocket,
  FaThumbsUp,
} from "react-icons/fa";
import Logo from "./logo";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <>
      <div className="mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>

      <header
        className={
          click
            ? "header-left header-menu-style-two menu-open"
            : "header-left header-menu-style-two"
        }
      >
        <div className="scroll-bar">
          <div className="hl-top">
            <div className="hl-logo">
              {/*<Link to="/">*/}
              <a href="#home">
                <Logo />
              </a>

              {/*</Link>*/}
            </div>
          </div>

          <Scrollspy
            className="nav nav-menu"
            items={[
              "tools",
              "testimonials",
              "resume",
              "system-example",
              "contactus",
            ]}
            currentClassName="active"
            offset={-30}
          >
            <li>
              <a className="nav-link " href="#tools" onClick={handleClick}>
                <FaComment />
                <span className="item">Инструменты</span>
              </a>
            </li>
            <li>
              <a
                className="nav-link "
                href="#testimonials"
                onClick={handleClick}
              >
                <FaThumbsUp />
                <span className="item">Преимущества</span>
              </a>
            </li>
            <li>
              <a className="nav-link " href="#resume" onClick={handleClick}>
                <FaRocket />
                <span className="item">Возможности</span>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#system-example"
                onClick={handleClick}
              >
                <FaLink />
                <span className="item">О системе</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#contactus" onClick={handleClick}>
                <FaPhone />
                <span className="item">Контакты</span>
              </a>
            </li>
          </Scrollspy>
        </div>
      </header>
    </>
  );
};

export default Header;
