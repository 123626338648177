import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      version="1"
    >
      <g className="layer" transform="scale(0.47)">
        <g fill="#ffffff" id="svg_22">
          <g id="svg_3" transform="translate(175.827 257.558)">
            <g id="svg_4">
              <path
                d="M -41.65 -189.38 L -41.65 -88.86 C -41.65 -88.06 -40.85 -87.06 -39.449999999999996 -87.06 L -13.569999999999997 -87.06 C -12.159999999999997 -87.06 -11.159999999999997 -88.06 -11.159999999999997 -88.86 L -11.159999999999997 -189.38 L -41.64999999999999 -189.38 L -41.65 -189.38 Z"
                id="svg_5"
              />
              <path d="M -72.55 -220.07999999999998" id="svg_5" />
              <path
                d="M -72.55 -220.07999999999998 L 19.540000000000006 -220.07999999999998 C 20.940000000000005 -220.07999999999998 21.740000000000006 -220.88 21.740000000000006 -221.88 L 21.740000000000006 -245.76 C 21.740000000000006 -246.56 20.940000000000005 -247.56 19.540000000000006 -247.56 L -72.55 -247.56 C -73.75999999999999 -247.56 -74.75999999999999 -246.56 -74.75999999999999 -245.76 L -74.75999999999999 -221.88 C -74.75999999999999 -220.88 -73.75999999999999 -220.07999999999998 -72.55 -220.07999999999998 Z"
                id="svg_5"
              />
            </g>
          </g>
        </g>
        <g fill="#ffffff" id="svg_6">
          <g id="svg_7" transform="translate(84.7634 257.558)">
            <g id="svg_8">
              <path
                d="M 53.24 -90.07 L -11.160000000000004 -165.91 L 52.63999999999999 -244.56 C 54.03999999999999 -245.96 53.03999999999999 -247.56 50.82999999999999 -247.56 L 18.12999999999999 -247.56 C 17.72999999999999 -247.56 16.529999999999987 -247.17000000000002 16.329999999999988 -246.96 L -44.06000000000002 -172.73000000000002 L -44.06000000000002 -245.76000000000002 C -44.06000000000002 -246.56000000000003 -44.87000000000002 -247.56000000000003 -46.070000000000014 -247.56000000000003 L -72.55000000000001 -247.56000000000003 C -73.76 -247.56000000000003 -74.76 -246.56000000000003 -74.76 -245.76000000000002 L -74.76 -96.89000000000001 C -74.76 -96.89000000000001 -35.63 -141.23000000000002 -31.620000000000005 -145.44 L 15.119999999999997 -87.66 C 15.319999999999997 -87.46 16.529999999999998 -87.06 16.929999999999996 -87.06 L 51.44 -87.06 C 53.44 -87.06 54.449999999999996 -88.47 53.239999999999995 -90.07000000000001 L 53.24 -90.07 Z"
                id="svg_9"
              />
              <path d="M 53.24 -90.07" id="svg_9" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_10"
          transform="matrix(1, 0, 0, 1, 151.74597167968747, -0.31795200705528615)"
        >
          <g id="svg_11" transform="translate(85.3256 291.955)">
            <g id="svg_12">
              <path
                d="M -68.99 -92.47 L -64.63 -87.06 L -67.31 -87.06 L -70.49000000000001 -91.25 L -71.35000000000001 -90.31 L -71.35000000000001 -87.06 L -73.43 -87.06 L -73.43 -97.4 L -71.35000000000001 -97.4 L -71.35000000000001 -92.42 L -66.88000000000001 -97.4 L -64.43 -97.4 L -68.99000000000001 -92.47 L -68.99 -92.47 Z"
                id="svg_13"
              />
              <path d="M -68.99 -92.47" id="svg_13" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_14"
          transform="matrix(1, 0, 0, 1, 151.74597167968747, -0.31795200705528615)"
        >
          <g id="svg_15" transform="translate(101.166 291.955)">
            <g id="svg_16">
              <path
                d="M -71.35 -87.06 L -73.42999999999999 -87.06 L -73.42999999999999 -97.4 L -71.35 -97.4 L -71.35 -87.06 Z"
                id="svg_17"
              />
              <path d="M -71.35 -87.06" id="svg_17" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_18"
          transform="matrix(1, 0, 0, 1, 151.74597167968747, -0.31795200705528615)"
        >
          <g id="svg_19" transform="translate(111.141 291.955)">
            <g id="svg_20">
              <path
                d="M -65.32 -95.79 L -68.83999999999999 -95.79 L -68.83999999999999 -87.06 L -70.89999999999999 -87.06 L -70.89999999999999 -95.79 L -74.41999999999999 -95.79 L -74.41999999999999 -97.4 L -65.32 -97.4 L -65.32 -95.79 Z"
                id="svg_21"
              />
              <path d="M -65.32 -95.79" id="svg_21" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_25"
          transform="matrix(1, 0, 0, 1, -51.15260314941406, -0.5179569721221924)"
        >
          <g id="svg_26" transform="translate(134.586 291.955)">
            <g id="svg_27">
              <path
                d="M -65.95 -88.7 L -65.95 -87.06 L -73.43 -87.06 L -73.43 -97.4 L -66.10000000000001 -97.4 L -66.10000000000001 -95.79 L -71.35000000000001 -95.79 L -71.35000000000001 -93.06 L -66.48 -93.06 L -66.48 -91.42 L -71.35000000000001 -91.42 L -71.35000000000001 -88.7 L -65.95 -88.7 Z"
                id="svg_28"
              />
              <path d="M -65.95 -88.7" id="svg_28" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_29"
          transform="matrix(1, 0, 0, 1, -51.15260314941406, -0.5179569721221924)"
        >
          <g id="svg_30" transform="translate(149.421 291.955)">
            <g id="svg_31">
              <path
                d="M -68.98 -97.62 C -67.63000000000001 -97.62 -66.58 -97.29 -65.82000000000001 -96.64 C -65.06 -95.98 -64.63000000000001 -95.11 -64.51 -94.04 L -64.49000000000001 -93.9 L -66.57000000000001 -93.9 L -66.60000000000001 -94.04 C -66.88000000000001 -95.4 -67.68 -96.08000000000001 -69.01 -96.08000000000001 C -69.85000000000001 -96.08000000000001 -70.53 -95.74000000000001 -71.04 -95.06000000000002 C -71.55000000000001 -94.38000000000001 -71.81 -93.46000000000002 -71.81 -92.29000000000002 C -71.81 -91.04000000000002 -71.55 -90.08000000000003 -71.04 -89.40000000000002 C -70.53 -88.72000000000001 -69.87 -88.39000000000001 -69.04 -88.39000000000001 C -68.38000000000001 -88.39000000000001 -67.86 -88.57000000000002 -67.46000000000001 -88.94000000000001 C -67.06 -89.31000000000002 -66.79 -89.83000000000001 -66.65 -90.50000000000001 L -66.62 -90.64000000000001 L -64.56 -90.64000000000001 L -64.57000000000001 -90.50000000000001 C -64.69000000000001 -89.37000000000002 -65.15 -88.48000000000002 -65.96000000000001 -87.83000000000001 C -66.77000000000001 -87.18 -67.82000000000001 -86.86000000000001 -69.13000000000001 -86.86000000000001 C -70.60000000000001 -86.86000000000001 -71.77000000000001 -87.33000000000001 -72.67000000000002 -88.29000000000002 C -73.55000000000001 -89.25000000000001 -73.99000000000001 -90.54000000000002 -73.99000000000001 -92.17000000000002 C -73.99000000000001 -93.83000000000001 -73.53000000000002 -95.16000000000001 -72.59 -96.14000000000001 C -71.65 -97.13000000000001 -70.45 -97.62000000000002 -68.98 -97.62000000000002 L -68.98 -97.62 Z"
                id="svg_32"
              />
              <path d="M -68.98 -97.62" id="svg_32" />
            </g>
          </g>
        </g>

        <g
          id="svg_33"
          transform="matrix(1, 0, 0, 1, -51.15260314941406, -0.5179569721221924)"
        >
          <g id="svg_34" transform="translate(165.786 291.955)">
            <g id="svg_35">
              <path
                d="M -68.88 -97.62 C -67.3 -97.62 -66.05 -97.13000000000001 -65.13 -96.15 C -64.22 -95.19000000000001 -63.76 -93.88000000000001 -63.76 -92.23 C -63.76 -90.61 -64.22 -89.3 -65.13 -88.33 C -66.05 -87.35 -67.3 -86.86 -68.88 -86.86 C -70.46 -86.86 -71.71 -87.35 -72.61999999999999 -88.33 C -73.53999999999999 -89.3 -73.99 -90.61 -73.99 -92.23 C -73.99 -93.88000000000001 -73.53999999999999 -95.19 -72.63 -96.15 C -71.72 -97.13000000000001 -70.47 -97.62 -68.88 -97.62 Z"
                id="svg_36"
                fill="#ffffff"
              />
              <path d="M -71.06 -89.4" id="svg_36" />
              <path
                d="M -71.06 -89.4 C -70.53 -88.72 -69.8 -88.39 -68.88 -88.39 C -67.96 -88.39 -67.24 -88.72 -66.72999999999999 -89.4 C -66.21 -90.09 -65.94999999999999 -91.03 -65.94999999999999 -92.23 C -65.94999999999999 -93.44 -66.21 -94.38000000000001 -66.72999999999999 -95.06 C -67.24 -95.74000000000001 -67.96 -96.08 -68.88 -96.08 C -69.81 -96.08 -70.53999999999999 -95.74 -71.06 -95.06 C -71.58 -94.38 -71.84 -93.44 -71.84 -92.23 C -71.84 -91.02000000000001 -71.58 -90.08 -71.06 -89.4 Z"
                id="svg_36"
              />
            </g>
          </g>
        </g>

        <g
          fill="#ffffff"
          id="svg_37"
          transform="matrix(1, 0, 0, 1, -51.15260314941406, -0.5179569721221924)"
        >
          <g id="svg_38" transform="translate(182.856 291.955)">
            <g id="svg_39">
              <path
                d="M -69.99 -97.62 C -68.80999999999999 -97.62 -67.89999999999999 -97.33 -67.28 -96.73 C -66.65 -96.15 -66.28 -95.36 -66.17 -94.37 L -68.28 -94.37 C -68.34 -94.9 -68.52 -95.32000000000001 -68.82000000000001 -95.62 C -69.13000000000001 -95.92 -69.54 -96.08 -70.07000000000001 -96.08 C -70.50000000000001 -96.08 -70.85000000000001 -95.97 -71.12 -95.76 C -71.39 -95.55000000000001 -71.53 -95.25 -71.53 -94.84 C -71.53 -94.4 -71.37 -94.06 -71.04 -93.81 C -70.71000000000001 -93.56 -70.13000000000001 -93.34 -69.32000000000001 -93.15 C -68.19000000000001 -92.89 -67.35000000000001 -92.5 -66.82000000000001 -91.97 C -66.28 -91.44 -66.01 -90.75 -66.01 -89.9 C -66.01 -88.97 -66.38000000000001 -88.22 -67.12 -87.67 C -67.86 -87.13 -68.77000000000001 -86.86 -69.84 -86.86 C -71.10000000000001 -86.86 -72.10000000000001 -87.13 -72.85000000000001 -87.69 C -73.60000000000001 -88.24 -74.03000000000002 -89.05 -74.13000000000001 -90.12 L -72.01 -90.12 C -71.91000000000001 -89.57000000000001 -71.66000000000001 -89.14 -71.28 -88.84 C -70.88 -88.54 -70.41 -88.39 -69.87 -88.39 C -69.39 -88.39 -68.99000000000001 -88.51 -68.67 -88.75 C -68.35000000000001 -89 -68.18 -89.31 -68.18 -89.7 C -68.18 -90.14 -68.34 -90.49000000000001 -68.65 -90.76 C -68.96000000000001 -91.03 -69.49000000000001 -91.26 -70.24000000000001 -91.44000000000001 C -71.4 -91.72000000000001 -72.27000000000001 -92.08000000000001 -72.84 -92.54 C -73.41 -93.01 -73.7 -93.69000000000001 -73.7 -94.59 C -73.7 -95.51 -73.34 -96.24000000000001 -72.62 -96.79 C -71.9 -97.35000000000001 -71.03 -97.62 -69.99000000000001 -97.62 L -69.99 -97.62 Z"
                id="svg_40"
              />
              <path d="M -69.99 -97.62" id="svg_40" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_41"
          transform="matrix(1, 0, 0, 1, -51.15260314941406, -0.5179569721221924)"
        >
          <g id="svg_42" transform="translate(197.571 291.955)">
            <g id="svg_43">
              <path
                d="M -68.81 -91.06 L -68.81 -87.06 L -70.85000000000001 -87.06 L -70.85000000000001 -91.06 L -74.53 -97.4 L -72.18 -97.4 L -69.76 -92.89 L -67.43 -97.4 L -65.15 -97.4 L -68.81 -91.06 Z"
                id="svg_44"
              />
              <path d="M -68.81 -91.06" id="svg_44" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_45"
          transform="matrix(1, 0, 0, 1, -51.15260314941406, -0.5179569721221924)"
        >
          <g id="svg_46" transform="translate(212.556 291.955)">
            <g id="svg_47">
              <path
                d="M -69.99 -97.62 C -68.80999999999999 -97.62 -67.89999999999999 -97.33 -67.28 -96.73 C -66.65 -96.15 -66.28 -95.36 -66.17 -94.37 L -68.28 -94.37 C -68.34 -94.9 -68.52 -95.32000000000001 -68.82000000000001 -95.62 C -69.13000000000001 -95.92 -69.54 -96.08 -70.07000000000001 -96.08 C -70.50000000000001 -96.08 -70.85000000000001 -95.97 -71.12 -95.76 C -71.39 -95.55000000000001 -71.53 -95.25 -71.53 -94.84 C -71.53 -94.4 -71.37 -94.06 -71.04 -93.81 C -70.71000000000001 -93.56 -70.13000000000001 -93.34 -69.32000000000001 -93.15 C -68.19000000000001 -92.89 -67.35000000000001 -92.5 -66.82000000000001 -91.97 C -66.28 -91.44 -66.01 -90.75 -66.01 -89.9 C -66.01 -88.97 -66.38000000000001 -88.22 -67.12 -87.67 C -67.86 -87.13 -68.77000000000001 -86.86 -69.84 -86.86 C -71.10000000000001 -86.86 -72.10000000000001 -87.13 -72.85000000000001 -87.69 C -73.60000000000001 -88.24 -74.03000000000002 -89.05 -74.13000000000001 -90.12 L -72.01 -90.12 C -71.91000000000001 -89.57000000000001 -71.66000000000001 -89.14 -71.28 -88.84 C -70.88 -88.54 -70.41 -88.39 -69.87 -88.39 C -69.39 -88.39 -68.99000000000001 -88.51 -68.67 -88.75 C -68.35000000000001 -89 -68.18 -89.31 -68.18 -89.7 C -68.18 -90.14 -68.34 -90.49000000000001 -68.65 -90.76 C -68.96000000000001 -91.03 -69.49000000000001 -91.26 -70.24000000000001 -91.44000000000001 C -71.4 -91.72000000000001 -72.27000000000001 -92.08000000000001 -72.84 -92.54 C -73.41 -93.01 -73.7 -93.69000000000001 -73.7 -94.59 C -73.7 -95.51 -73.34 -96.24000000000001 -72.62 -96.79 C -71.9 -97.35000000000001 -71.03 -97.62 -69.99000000000001 -97.62 L -69.99 -97.62 Z"
                id="svg_48"
              />
              <path d="M -69.99 -97.62" id="svg_48" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_49"
          transform="matrix(1, 0, 0, 1, -51.15260314941406, -0.5179569721221924)"
        >
          <g id="svg_50" transform="translate(227.271 291.955)">
            <g id="svg_51">
              <path
                d="M -65.32 -95.79 L -68.83999999999999 -95.79 L -68.83999999999999 -87.06 L -70.89999999999999 -87.06 L -70.89999999999999 -95.79 L -74.41999999999999 -95.79 L -74.41999999999999 -97.4 L -65.32 -97.4 L -65.32 -95.79 Z"
                id="svg_52"
              />
              <path d="M -65.32 -95.79" id="svg_52" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_53"
          transform="matrix(1, 0, 0, 1, -51.15260314941406, -0.5179569721221924)"
        >
          <g id="svg_54" transform="translate(242.211 291.955)">
            <g id="svg_55">
              <path
                d="M -65.95 -88.7 L -65.95 -87.06 L -73.43 -87.06 L -73.43 -97.4 L -66.10000000000001 -97.4 L -66.10000000000001 -95.79 L -71.35000000000001 -95.79 L -71.35000000000001 -93.06 L -66.48 -93.06 L -66.48 -91.42 L -71.35000000000001 -91.42 L -71.35000000000001 -88.7 L -65.95 -88.7 Z"
                id="svg_56"
              />
              <path d="M -65.95 -88.7" id="svg_56" />
            </g>
          </g>
        </g>
        <g
          fill="#ffffff"
          id="svg_57"
          transform="matrix(1, 0, 0, 1, -51.15260314941406, -0.5179569721221924)"
        >
          <g id="svg_58" transform="translate(257.046 291.955)">
            <g id="svg_59">
              <path
                d="M -62.26 -97.4 L -62.26 -87.06 L -64.34 -87.06 L -64.34 -91.84 C -64.34 -93.49000000000001 -64.33 -94.67 -64.31 -95.39 C -64.59 -94.19 -64.91 -93.01 -65.26 -91.86 L -66.71000000000001 -87.06 L -68.96000000000001 -87.06 L -70.45 -91.87 C -70.83 -93.14 -71.15 -94.31 -71.38000000000001 -95.37 C -71.37 -94.65 -71.35000000000001 -93.47 -71.35000000000001 -91.84 L -71.35000000000001 -87.06 L -73.43 -87.06 L -73.43 -97.4 L -70.15 -97.4 L -67.82000000000001 -88.97 L -65.48 -97.4 L -62.260000000000005 -97.4 L -62.26 -97.4 Z"
                id="svg_60"
              />
              <path d="M -62.26 -97.4" id="svg_60" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
