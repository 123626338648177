const Separated = () => {
  return (
    <div
      className="separated"
      style={{
        backgroundImage: 'url("static/img/border.png")',
      }}
    ></div>
  );
};

export default Separated;
