import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Awards from "../award/Awards";

export default function ProductList({ products }) {
  return (
    <section id="system-example" className="section theme-light ">
      <div className="container">
        <div className="title">
          <h3>Как это выглядит глазами пациента</h3>
        </div>
        <Awards AwardContnet={products} />
      </div>
    </section>
  );
}
