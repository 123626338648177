import React from "react";
import TextLoop from "react-text-loop";
import Social from "../Social";
import ParticlesAnimation from "../particles-animation";

const sliderContent = {
  name: "система роста доходимости, возвращаемости и NPS",
  description:
    "Люди проводят больше всего времени в мессенджерах, в них и работает наша система общения с пациентами",
  btnText: "посмотреть, как работает",
};

const Slider = () => {
  return (
    <>
      <section
        id="home"
        className="home-banner home-banner-3"
        style={{
          backgroundImage: 'url("static/img/home-banner-3.jpg")',
        }}
      >
        <ParticlesAnimation />

        <div className="container">
          <div className="header-social ">
            <Social cls={"social"} />
          </div>

          <div className="row full-screen ">
            <div className="align-items-start">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  для медицинских клиник
                </h6>
                <h1
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  className="kit-med"
                >
                  KIT MED
                </h1>

                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>

                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="700"
                >
                  <TextLoop>
                    <p className="loop-text lead">
                      Оповещения пациентов о визитах
                    </p>
                    <p className="loop-text lead">
                      Подтверждение или отмена визита
                    </p>
                    <p className="loop-text lead">Сбор обратной связи</p>
                    <p className="loop-text lead">Оповещения об акциях</p>
                    <p className="loop-text lead">Периодические напоминания</p>
                    <p className="loop-text lead">Обмен данными с МИС</p>
                  </TextLoop>
                </div>
                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  {sliderContent.description}
                </p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a className="px-btn px-btn-white" href="#system-example">
                    {sliderContent.btnText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Slider;
