import React from "react";
import {
  FaEnvelope,
  FaInstagram,
  FaPhoneSquare,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaInstagram />, link: "https://www.instagram.com/ecosystemkit" },
  { Social: <FaTelegramPlane />, link: "https://t.me/ecosystemkit" },
  { Social: <FaWhatsapp />, link: "https://wa.me/79198914107" },
  { Social: <FaEnvelope />, link: "mailto:med@ecosystemkit.ru" },
  {
    Social: <FaPhoneSquare />,
    link: "tel:+79198914107",
  },
];

const Social = ({ cls }) => {
  return (
    <>
      {SocialShare.map((val, i) => (
        <a
          className={cls ? cls : ""}
          key={i}
          href={`${val.link}`}
          rel="noreferrer"
          target="_blank"
        >
          {val.Social}
        </a>
      ))}
    </>
  );
};

export default Social;
