import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider({ content, slidesToShow = 1 }) {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
    margin: 5,
    responsive: [
      {
        breakpoint: 3991,
        settings: {
          slidesToShow,
        },
      },
      {
        breakpoint: 2420,
        settings: {
          slidesToShow,
        },
      },
    ],
  };

  return (
    <div className="testimonial-wrapper ">
      <Slider {...settings}>
        {content.map((val, i) => (
          <div key={i}>
            <div className="testimonial-01 media-body">
              <div className="avatar">
                <img
                  src={`static/img/testimonial/${val.imageName}.jpg`}
                  alt={val.reviewerName}
                ></img>
              </div>

              <div className="media-body">
                {val.reviewerName && <h6>{val.reviewerName}</h6>}
                {val.designation && <span>{val.designation}</span>}
                {val.desc && (
                  <ul>
                    {val.desc.map((value, index) => {
                      return <li key={index}>{value}</li>;
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
