import React from "react";
import Testimonials from "../testimonial/Testimonial";
import Tools from "../tools/Tools";
import Awards from "../award/Awards";
import Separated from "../separated";

const Advantages = () => {
  const advantages = [
    {
      imageName: "clinic",
      desc: [
        "Рост показателей доходимости, возвращаемости, NPS",
        "Полную интеграцию с МИС",
        "Модульность и экономную масштабируемость",
        "Безопасность и стабильность работы (ваш сервер)",
        "Еще одну точку касания с аудиторией",
        "Имиджевую составляющую",
        "Прозрачную стоимость",
        "Существенную разгрузку менеджеров от рутинных операций",
        "Гибкую и разную аналитику под запрос",
      ],
      reviewerName: "Что получает клиника?",
      designation:
        "Автоматизацию рутиных процессов и фокус на главном - заботе о пациентах",
    },
    {
      imageName: "patient",
      desc: [
        "Может смотреть свои текущие записи, данные о врачах, записываться, подтверждать или отменять визит в режиме 24/7",
        "заблаговоременно получает напоминания о приемах у врача",
        "цифрового помощника, который уже встроен в мессенджер, не надо скачивать другие приложения",
        "не занимает экранное место, но всегда доступен в телефоне",
        "систему “узнающую” клиента по телефону, не надо помнить логин/пароль для входа",
      ],
      reviewerName: "Что получает пациент?",
      designation: "Получает сервис и удобство",
    },
  ];

  const partners = [
    {
      imageName: "client-medical-park",
    },
    {
      imageName: "client-pravilnaya-medicina",
    },
    {
      imageName: "client-lime-scin-care",
    },
    {
      imageName: "client-plus",
    },
  ];

  const AwardContnet = [
    {
      img: "a1",
      awardName: "Доходимости",
      awardFor:
        "напоминания о приемах, просмотр графика предстоящих визитов, оперативная работа с отмененными визитами",
    },
    {
      img: "a2",
      awardName: "Возвращаемости",
      awardFor:
        "напоминания о текущих визитах, напоминания о плановых рекомендуемых осмотрах, приглашение на чекапы, сезонные осмотры, комплексные предложения",
    },
    {
      img: "a3",
      awardName: "NPS",
      awardFor:
        "регулярный сбор отзывов, переадресация на сайты-отзовики, оперативная отработка негатива и опросы по обратной связи",
    },
  ];

  return (
    <>
      <section className="section theme-light dark-bg">
        <div className="container">
          <div id="tools" className="title">
            <h3>Наши инструменты</h3>
          </div>
          <Tools />
          <Separated />

          <div id="testimonials" className="title">
            <h3>Преимущества</h3>
          </div>
          <Testimonials content={advantages} />
          <Separated />

          <div className="title">
            <h3>Вы получаете рост:</h3>
          </div>
          <Awards AwardContnet={AwardContnet} />
          <Separated />

          <div id="testimonials" className="title">
            <h3>Клиенты</h3>
          </div>
          <Testimonials content={partners} slidesToShow={2} />
        </div>
      </section>
    </>
  );
};

export default Advantages;
