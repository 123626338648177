import Social from "../Social";
import React from "react";

const Biography = () => {
  return (
    <div className="row align-items-center justify-content-center">
      <div className="col-md-6 col-lg-4">
        <div className="biography">
          <div className="img">
            <div className="img-in">
              <img src="static/img/biography/about-me.jpg" alt="Biography" />
            </div>
            <div className="nav social-icons justify-content-center">
              <Social />
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-7 ml-auto">
        <div className="biography-info">
          <div className="about-text">
            <div className="info">
              <div className="title">
                <h3>Пыдык Наталья</h3>
                <p>Сооснователь ECOSYSTEM KIT</p>
              </div>
            </div>
            <h5>
              Маркетолог, предприниматель, ментор программ Сколково, спикер
              федеральных бизнес-программ.
            </h5>
            <h5>15 лет опыта в системе «экономика-продажи-маркетинг».</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Biography;
